/**
 * 共通JS
 * サイドバーなど
 */

import Const from './const';
import { throttle, getCookie, deleteCookie } from './library';

export class Common {
    constructor() {
        // APIトークンをlocalstorageに保存
        this.saveApiTokenToStorage();

        // SPナビゲーションバー
        this.toggleNav();

        // header表示・非表示
        this.toggleHeader();

        // 伸縮するテキストエリア
        this.flexTextarea();

        // TOPへスクロールボタン表示・非表示
        this.scrollTopEl = document.getElementsByClassName('ScrollTop');
        if( this.scrollTopEl.length > 0 ){
            this.scrollTop(this.scrollTopEl[0]);
        }

        // スムーススクロール
        this.scrollLinkEls = document.querySelectorAll('a[href^="#"]');
        if( this.scrollLinkEls.length > 0 ){
            this.scrollLinkEls.forEach(el => el.addEventListener('click', ev => this.smoothScroll(el, ev)));
        }

        // 次の要素を発火
        this.fireNextEls = Array.from(document.getElementsByClassName('fire-next'));
        this.fireNextEls.forEach(el => el.addEventListener('click', ev => this.fireNext(el, ev)));

        // モーダル
        // 1. モーダル開く
        this.modalOpenBtnEls = Array.from(document.getElementsByClassName('modal-open'));
        this.modalOpenBtnEls.forEach(el => el.addEventListener('click', ev => this.openModal(el)));
        this.modalOpenWrapEls = Array.from(document.getElementsByClassName('modal-wrap'));
        this.modalOpenWrapEls.forEach(el => el.addEventListener('click', ev => this.openModal(ev.target)));
        // 2. モーダル閉じる
        this.modalCloseBtnEls = document.querySelectorAll('.modal-close, .modal-cancel');
        this.modalCloseBtnEls.forEach(el => el.addEventListener('click', ev => this.closeModal(el)));
        this.modalCloseWrapEls = Array.from(document.getElementsByClassName('modal-wrap'));
        this.modalCloseWrapEls.forEach(el => el.addEventListener('click', ev => this.closeModal(ev.target)));
        // 3. モーダルの外クリックでモーダル閉じる
        if( document.querySelector('.modal-container') ){
            document.addEventListener('click', ev => {
                if( ev.target.closest('.modal-container') && ev.target.closest('.modal-container').classList.contains('active') ){
                    if( !ev.target.closest('.modal-body') ){
                        this.closeModal(ev.target);
                    }
                }
            });
        }

        // notify-for-js
        this.notifyCloseEls = Array.from(document.getElementsByClassName('notify-close'));
        this.notifyCloseEls.forEach(el => el.addEventListener('click', () => this.closeNotify(el)));
    }

    /**
     * ログイン時にサーバーからAPIトークンがcookieに渡されるので
     * それをlocalStorageに保存してcookieから削除する
     */
    saveApiTokenToStorage() {
        const companyToken = getCookie('company_api_token_forjs');
        if( companyToken ){
            localStorage.setItem('company_api_token', companyToken);
            deleteCookie('company_api_token');
        }
        const adminToken = getCookie('admin_api_token_forjs');
        if( adminToken ){
            localStorage.setItem('admin_api_token', adminToken);
            deleteCookie('admin_api_token');
        }
    }

    /**
     * SPナビゲーションバー表示・非表示
     */
    toggleNav() {
        const body = document.body;
        var hamburger = document.getElementById('Menu');
        const blackBg = document.getElementById("js-menu-bg");
        if( hamburger && blackBg ){
            hamburger.addEventListener('click', function() {
                body.classList.toggle('nav-open');
                hamburger.classList.toggle('active');
            });
            blackBg.addEventListener("click", function() {
                body.classList.remove("nav-open");
                hamburger.classList.remove("active");
            });
        }
    }

    /**
     * ヘッダー表示・非表示
     * 下スクロールで非表示、上スクロールで表示
     */
    toggleHeader() {
        let startPosition = 0;
        let windowPosition;
        let headerEl = document.querySelector('body > header');

        if( headerEl ){
            window.addEventListener('scroll',
                throttle(() => {
                    windowPosition = window.scrollY;

                    // スクロール位置がheader高さより小さい場合は必ずheader表示
                    if( windowPosition <= 80 ){
                        headerEl.style.top = 0;
                    }
                    // スクロール量が初期位置のほうが大きければ、
                    // 上にスクロールしているのでヘッダーフッターを出現させる
                    else if( startPosition > windowPosition ){
                        headerEl.style.top = 0;
                    }
                    // スクロール量が現在位置のほうが大きければ、
                    // 下にスクロールしているのでッダーフッターを隠す
                    else{
                        headerEl.style.top = '-80px';
                    }

                    // 現在の位置を更新する
                    startPosition = windowPosition;
                }, 100)
            );
        }
    }

    /**
     * 伸縮するテキストエリア
     */
    flexTextarea() {
        document.querySelectorAll('.flex-textarea').forEach(el => {
            const dummy = el.querySelector('.flex-textarea-dummy');
            const textarea = el.querySelector('.flex-textarea-textarea');
            // 初期設定
            dummy.textContent = textarea.value + '\u200b'

            // 文字が入力されたとき
            textarea.addEventListener('input', e => {
                dummy.textContent = e.target.value + '\u200b'
            })
        });
    }

    /**
     * TOPへスクロールボタン表示・非表示
     */
    scrollTop(el) {
        window.addEventListener('scroll',
            throttle(() => {
                if( window.scrollY < 40 ){
                    el.classList.remove('isActive');
                }else{
                    el.classList.add('isActive');
                }
            }, 100)
        );
    }

    /**
     * #のついたリンク先へスムーススクロールする
     */
    smoothScroll(el, ev) {
        ev.preventDefault();

        const href = el.getAttribute('href');
        let position;
        if( href == '#' || href == '' ){
            position = 0;
        }else{
            const target = document.getElementById(href.replace('#', ''));
            if( target ){
                position = document.getElementById(href.replace('#', '')).offsetTop;
            }else{
                position = 0;
            }
        }console.log('position', position);
        window.scrollTo({
            top: position,
            behavior: 'smooth'
        });
    }

    /**
     * 次の要素を発火
     */
    fireNext(el, ev) {
        ev.preventDefault();
        el.nextElementSibling.click();
    }

    /**
     * モーダルを開く
     */
    openModal(el) {
        const el1 = el.closest('.modal-open');
        const el2 = el.closest('.modal-wrap-open');
        el = [el1, el2].find(_el => _el != null);

        if( el ){
            const containerEls = document.getElementById(el.dataset.target);
            containerEls.classList.add('active');
        }
    }

    /**
     * モーダルを閉じる
     */
    closeModal(el) {
        const containerEls = el.closest('.modal-container');
        if( containerEls ){
            containerEls.classList.remove('active');
        }
    }

    /**
     * notify
     */
    closeNotify(el) {
        const notifyEl = el.closest('.notification-for-js');
        notifyEl.style.display = 'none';
    }
}

window.Common = new Common();
